.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #F9F9FA;
  padding: 80px 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .logo {
    width: 130px;
    height: 40px;
  }

  .form {
    display: grid;
    grid-gap: 32px;
    width: 316px;
    background-color: var(--white);
    box-shadow: 0 0 3px rgba(68, 63, 53, 0.25);
    border-radius: 6px;
    padding: 24px;
    margin: 24px 0;
  }

  .error {
    color: var(--red);
  }
}
