.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .showOnPage {
    font-size: 11px;
    color: var(--t-second);
  }

  .rowsPerPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F2F2F7;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0 20px 0 10px;

    .amount {
      font-size: 11px;
      color: var(--t-second);
      padding: 4px 6px;
    }

    .selected {
      color: var(--t-white);
      background: rgba(37, 37, 37, 0.65);
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .number {
      display: block;
      font-size: 11px;
      color: var(--t-prime);
      text-align: center;
      width: 28px;
      height: 26px;
      border-radius: 6px;
      padding: 5px 0;
      margin: 0 5px;
      cursor: pointer;
    }

    .dots {
      display: block;
      font-size: 11px;
      color: var(--t-prime);
    }

    .selected {
      background-color: var(--bg-page);
    }

    .icon {
      width: 27px;
      height: 27px;
      cursor: pointer;
    }
  }
}
