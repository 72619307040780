.CalendarDay__default {
  border-color: transparent;
}

.CalendarDay__default_2 {
  color: var(--t-prime);
}

.CalendarDay__selected_span {
  background: var(--orange-50);
  color: var(--t-prime);
  border: 1px solid var(--orange-50);
}

.CalendarDay__selected {
  background: var(--main);
  color: var(--t-white);
}

.CalendarDay__today {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background: transparent !important;
  color: var(--t-second) !important;
  border: 1px solid var(--border) !important;
  margin: 5px;
  opacity: 0.5;
}

.CalendarDay__blocked_out_of_range {
  color: var(--t-second) !important;
  opacity: 0.5;
}

.CalendarDay__default_2:hover,
.CalendarDay__selected:hover,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--main);
  color: var(--t-white);
  border: 1px solid var(--main);
}

.CalendarDay__selected_end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-color: transparent;
}

.CalendarDay__selected_start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-color: transparent;
}

.DayPicker_weekHeader_li small {
  font-size: 11px;
  line-height: 14px;
  color: var(--t-second);
  letter-spacing: 0.15px;
}

.date-picker-nav-prev {
  position: absolute;
  top: 18px;
  left: 14px;
}

.date-picker-nav-next {
  position: absolute;
  top: 18px;
  right: 14px;
}

.CalendarMonth_caption strong {
  font-size: 14px;
  color: var(--t-prime);
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}
