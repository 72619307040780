//noinspection CssInvalidAtRule
@import-normalize;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a,
p,
span,
label,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  color: var(--t-prime);
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style-type: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

textarea:focus, input:focus, span:focus {
  outline: none;
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: var(--t-third);
  opacity: 1;
}

img {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &::selection {
    background: transparent;
  }

  &::-moz-selection {
    background: transparent;
  }
}

br {
  display: block;
  content: "";
  margin: 14px 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1400;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: max-content;
  background: var(--bg-page);
}

main {
  padding: 50px 0 0 200px;
}

.select-table {
  display: block;
  font-size: 14px;
  color: var(--t-prime);
  line-height: 20px;
  white-space: pre-line;
  text-align: center;
  margin: 345px 20px 20px 20px;
}
