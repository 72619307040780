.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 218px;
  height: 28px;
  background-color: var(--bg-page);
  border: 1px solid var(--border);
  box-shadow: inset 0 2px 0 rgba(225, 228, 232, 0.2);
  border-radius: 6px;
  margin: 0 16px;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .input {
    width: 100%;
    height: 100%;
    font-size: 11px;
    color: var(--t-prime);
    background: transparent;
    border: none;
    padding: 0 16px;

    &::placeholder {
      color: var(--t-third);
    }
  }
}

.active {
  border: 0.5px solid var(--main);
  box-shadow: none;
}
