.container {
  background-color: var(--white);
  border-radius: 6px;
  filter: drop-shadow(0 0 3px rgba(68, 63, 53, 0.25));

  .header {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      border-bottom: 2px solid #F2F2F7;
      padding: 0 20px;

      .title {
        font-size: 14px;
        color: var(--t-prime);
        font-family: var(--f-semibold);
        text-transform: uppercase;
        margin-right: auto;
      }

      .tooltip {
        margin: 0 16px 0 0;
      }

      .clear {
        font-size: 14px;
        color: var(--main);
        font-family: var(--f-semibold);
        cursor: pointer;
        visibility: hidden;
      }

      .visible {
        visibility: visible;
      }
    }

    .columns {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #F2F2F7;
      padding: 0 5px;

      .column {
        width: 100%;
        cursor: pointer;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: max-content;
          padding: 4px 8px;
          border-radius: 5px;
        }

        .sorted {
          background-color: var(--bg-page);
        }

        .label {
          font-size: 11px;
          color: var(--t-prime);
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0.15px;
          font-family: var(--f-semibold);
          margin-right: 8px;
        }

        .tooltip {
          margin-left: 0;
          margin-right: 8px;
        }
      }

      .actionsColumn {
        display: block;
        min-width: 90px;
        font-size: 11px;
        color: var(--t-prime);
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.15px;
        font-family: var(--f-semibold);
        text-align: center;
      }
    }
  }

  .rows {
    min-height: 300px;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 34px;
      border-top: 1px solid #F2F2F7;

      .cell {
        display: block;
        font-size: 11px;
        color: var(--t-prime);
        width: 100%;
        height: 100%;
        padding: 8px;
        word-break: break-word;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        min-width: 90px;
        height: 100%;

        .action {
          font-size: 11px;
          color: var(--main);
          font-weight: 600;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.15px;
          cursor: pointer;
        }
      }
    }

    .empty {
      display: block;
      font-size: 14px;
      color: var(--t-third);
      text-align: center;
      padding-top: 150px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-top: 2px solid #F2F2F7;
    padding: 0 22px;
  }
}
