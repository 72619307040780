.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(12, 11, 9, 0.5);
  overflow: auto;
  z-index: 1300;

  .content {
    max-width: max-content;
    position: relative;
    background-color: var(--white);
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 6px;
  }
}
