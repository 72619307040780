@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --f-regular: 'Inter-Regular', sans-serif;
  --f-medium: 'Inter-Medium', sans-serif;
  --f-semibold: 'Inter-SemiBold', sans-serif;
}

* {
  font-family: var(--f-regular);
  font-weight: 400;
  line-height: 135%;
}

.f-regular {
  font-family: var(--f-regular);
  font-weight: 400;
}

.f-medium {
  font-family: var(--f-medium);
  font-weight: 500;
}

.f-semibold {
  font-family: var(--f-semibold);
  font-weight: 600;
}

.f-20 {
  font-size: 20px;
}

.f-17 {
  font-size: 17px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px;
}

h1 {
  font-size: 20px;
  color: var(--t-prime);
  font-family: var(--f-semibold);
}

h2 {
  font-size: 17px;
  color: var(--t-prime);
  font-family: var(--f-semibold);
}
