.container {
  display: grid;
  grid-template-columns: 1030px;
  padding: 40px;

  .modal {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    width: 380px;
    height: 100%;
    padding: 20px;

    .title {
      font-size: 14px;
      text-transform: uppercase;
    }

    .text {
      font-size: 11px;
      color: var(--t-second);
      white-space: pre-line;
    }

    .confirm {
      margin: 30px 0 10px 0;
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 18px;
      margin-top: auto;
    }
  }
}
