.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .radioOuterCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border: 1px solid var(--main);
    border-radius: 50%;
  }

  .radioInnerCircle {
    width: 8px;
    height: 8px;
    background-color: var(--main);
    border-radius: 50%;
  }

  .label {
    font-size: 14px;
    color: var(--t-prime);
    margin-left: 10px;
  }
}
