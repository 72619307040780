:root {
  --black: #000000;
  --white: #FFFFFF;
  --line: #D5D3CE;
  --input: #F6F5F4;
  --border: #D9DDE8;
  --bg-page: #F4F4F4;
  --bg-footer: #F9F7F6;
  --bg-container: #FFEBC9;
  --bg-ui: #FDFCFC;
  --gradient: linear-gradient(270deg, #FFA901 2.48%, #FFC201 100%);

  --main: #FF8B02;
  --gray: #DCDDDE;
  --green: #009F15;
  --blue: #3E85FF;
  --red: #DF2626;
  --orange-50: #FFF3E5;

  --t-prime: #0C0B09;
  --t-second: #443F35;
  --t-third: #8C8983;
  --t-white: #FFFFFF;
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.line {
  color: var(--line);
}

.input {
  color: var(--input);
}

.main {
  color: var(--main);
}

.gray {
  color: var(--gray);
}

.green {
  color: var(--green);
}

.blue {
  color: var(--blue);
}

.red {
  color: var(--red);
}

.t-prime {
  color: var(--t-prime);
}

.t-second {
  color: var(--t-second);
}

.t-third {
  color: var(--t-third);
}

.t-white {
  color: var(--t-white);
}
