.container {
  position: relative;

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 190px;
    height: 28px;
    background-color: var(--bg-page);
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 0 8px;
    cursor: pointer;

    .date {
      font-size: 11px;
      color: var(--t-second);
      font-family: var(--f-semibold);
      margin: 0 8px;
    }
  }

  .picker {
    display: flex;
    flex-direction: column;
    width: 804px;
    height: 480px;
    background-color: var(--white);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    position: absolute;
    top: 36px;
    left: -200%;
    z-index: 1300;

    .content {
      display: grid;
      grid-template-columns: 170px 1fr;
      width: 100%;
      height: 408px;

      .presets {
        border-right: 0.5px solid var(--border);

        .item {
          font-size: 14px;
          color: var(--t-prime);
          border-bottom: 0.5px solid var(--border);
          padding: 10px 16px;
          cursor: pointer;
        }

        .selectedItem {
          background: var(--input);
        }
      }

      .wrapper {
        .selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 56px;
          background-color: var(--bg-page);

          .date {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: var(--t-third);
            width: 282px;
            height: 32px;
            background-color: var(--white);
            border: 1px solid var(--border);
            border-radius: 6px;
            margin-left: 10px;
          }
        }
      }
    }

    .actions {
      display: grid;
      grid-template-columns: repeat(2, 150px);
      grid-column-gap: 18px;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 72px;
      background: var(--bg-ui);
      border-top: 1px solid var(--border);
      padding-right: 36px;
    }
  }
}
