.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }

  .label {
    font-size: 14px;
    color: var(--t-prime);
    text-align: left;
    margin-left: 8px;
  }
}
