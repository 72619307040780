.container {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100vh;
  background-color: var(--white);
  border: 1px solid var(--border);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  padding-top: 50px;

  .navLink {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--t-prime);
    font-weight: bold;
    text-decoration: none;
    width: 100%;
    height: 40px;
    padding-left: 24px;
  }

  .active {
    background-color: #FFEECC;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--border);
    margin: 16px 0 8px 0;
  }
}
