.container {
  display: grid;
  grid-row-gap: 10px;

  .label {
    font-size: 14px;
    color: var(--t-prime);
    font-family: var(--f-semibold);
  }

  .field {
    display: flex;
    flex-direction: row;
    position: relative;

    .input {
      width: 100%;
      height: 37px;
      font-size: 14px;
      color: var(--t-prime);
      border: 1px solid var(--border);
      border-radius: 6px;
      padding: 0 10px;

      &:focus {
        border-color: var(--main);
      }

      &::placeholder, &:disabled  {
        color: var(--t-third);
      }
    }

    .startAdornment {
      width: 76px;
      border: 1px solid var(--border);
      border-right: none;
      border-radius: 6px 0 0 6px;

      + .input {
        border-radius: 0 6px 6px 0;
      }
    }

    .error {
      border-color: var(--red);
    }

    .errorIcon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: calc(50% - 10px);
    }
  }

  .errorText {
    font-size: 11px;
    color: var(--red);
  }
}
