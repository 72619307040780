.button {
  width: 100%;
  height: 32px;
  border-radius: 6px;
  padding: 0 10px;
  cursor: pointer;

  .label {
    font-size: 14px;
    color: var(--t-white);
    font-family: var(--f-semibold);
  }
}

.primary {
  background-color: var(--main);

  &:hover {
    background-color: #E47703;
  }

  &:disabled {
    background-color: var(--gray);
    cursor: default;
  }
}

.secondary {
  background-color: var(--white);
  border: 1px solid var(--border);

  .label {
    color: var(--main);
  }

  &:hover {
    background-color: var(--input);
  }

  &:disabled {
    background-color: var(--gray);
    cursor: default;
  }
}

.text {
  background-color: transparent;

  .label {
    color: var(--main);
  }

  &:hover {
    background-color: var(--input);
  }

  &:disabled {
    .label {
      color: var(--t-third);
    }

    cursor: default;
  }
}

.destructive {
  background-color: var(--red);

  &:hover {
    background-color: #CB2020;
  }

  &:disabled {
    background-color: var(--gray);
    cursor: default;
  }
}
