.container {
  display: inline-block;
  vertical-align: middle;
  line-height: 12px;
  position: relative;
  margin-left: 10px;

  .content {
    display: none;
    width: 186px;
    font-size: 11px;
    white-space: pre-line;
    background-color: var(--orange-50);
    filter: drop-shadow(0px -2px 5px rgba(0, 0, 0, 0.12));
    border-radius: 7px;
    position: absolute;
    top: 100%;
    left: 100%;
    padding: 10px;
    margin: 10px 0 0 -22px;
    z-index: 10004;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 16px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--orange-50) transparent;
    }
  }

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover .content {
    display: block;
  }
}
