.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: var(--white);
  box-shadow: 0 0 3px rgba(68, 63, 53, 0.25);
  padding: 0 60px 0 5px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .logo {
    width: 130px;
    height: 40px;
  }

  .dropdown {
    position: relative;
    display: inline-block;

    .account {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0 5px 10px;
      cursor: pointer;

      .email {
        font-size: 14px;
        color: var(--t-second);
        margin-right: 12px;
      }
    }

    .menu {
      display: none;
      position: absolute;
      right: 0;
      min-width: 215px;
      background: var(--white);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 5px;

      .item {
        display: block;
        font-size: 14px;
        color: var(--t-prime);
        padding: 14px 32px;
        cursor: pointer;
      }
    }

    &:hover .menu {
      display: block;
    }
  }
}
