.select {
  position: relative;
  width: 100%;
  height: 100%;

  .input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0 4px 0 10px;

    .label {
      display: block;
      font-size: 14px;
      color: var(--t-prime);
    }

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .dropdown {
    position: absolute;
    top: 110%;
    width: 100%;
    height: max-content;
    background: var(--white);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 4px;
    z-index: 10;

    .label {
      display: block;
      font-size: 14px;
      color: var(--t-prime);
      padding: 5px 12px;
      cursor: pointer;

      &.selected {
        font-weight: 500;
        background: var(--bg-container);
        border-radius: 4px;
      }
    }
  }

  &.disabled {
    > .input {
      background: var(--input);
      border-radius: 6px 0 0 6px;
      cursor: default;

      * {
        opacity: 0.5;
      }
    }
  }
}
